/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Home from '@mui/icons-material/HomeRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PhotoSizeSelectSmallRoundedIcon from '@mui/icons-material/PhotoSizeSelectSmallRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import PresentToAllRoundedIcon from '@mui/icons-material/PresentToAllRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InfoIcon from '@mui/icons-material/Info';
import SvgIcon from '@mui/material/SvgIcon';

const VariableInsertIcon = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
      <path d="M120-280v-400h720v160h-80v-80H200v240h360v80H120Zm80-80v-240 240Zm664 200L720-303v123h-80v-260h260v80H776l144 144-56 56Z" />
    </svg>
  </SvgIcon>
);

const MenuIcon = ({ iconName, onClick, id }) => {
  const [iconEl, setIconEl] = useState(null);
  const menuList = {
    home: <Home />,
    'open-menu': <ArrowForwardOutlinedIcon onClick={onClick} id={id} />,
    'close-menu': <ArrowBackOutlinedIcon onClick={onClick} id={id} />,
    'trans-create': <AddCircleOutlineRoundedIcon />,
    'package-create': <AddCircleRoundedIcon />,
    'package-separation': <AltRouteRoundedIcon />,
    'forming-loss-declare': <PostAddIcon />,
    'forming-stock': <FactCheckIcon />,
    'trans-export': <UnarchiveRoundedIcon />,
    'trans-report': <AssessmentOutlinedIcon />,
    'powder-info': <InfoIcon />,
    'powder-qc': <VariableInsertIcon />,
    'qc-forming': <CenterFocusStrongRoundedIcon />,
    'qc-sintering-inspection': <VisibilityOutlinedIcon />,
    'qc-sintering-size': <PhotoSizeSelectSmallRoundedIcon />,
    'qc-sintering-electrical': <FlashOnRoundedIcon />,
    'qc-sintering-ng': <BugReportRoundedIcon />,
    'qc-export': <PresentToAllRoundedIcon />,
    'qc-report': <AssessmentRoundedIcon />,
    'settings-privilege': <SupervisedUserCircleOutlinedIcon />,
    'settings-config': <SettingsRoundedIcon />,
  };

  useEffect(() => {
    setIconEl(menuList[iconName]);
  }, []);
  return iconEl !== null ? iconEl : '';
};

MenuIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

export default MenuIcon;
