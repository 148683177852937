import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '../List/List';
import ListItemText from '../ListItemText/ListItemText';
import ListItem from '../ListItem/ListItem';
import MenuIcon from '../MenuIcon/MenuIcon';
import ListItemIcon from '../ListItemIcon/ListItemIcon';
import useStyles from './useStyles';
import { loginType } from '../../constants/enums';
import Divider from '../Divider/Divider';
import MainMenuItem from './MainMenuItem';

const MainMenu = ({ getMenuStatus }) => {
  const location = useLocation();
  const [menuList, setMenuList] = useState({
    home: [{ text: 'Home', icon: 'home', path: '/' }],
    trans: [],
    powderSystemList: [],
    qcs: [],
    admin: [],
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const { classes } = useStyles();
  const toggleMenu = () => {
    setMenuOpen((current) => !current);
  };

  const sortList = (array) => array.sort((prev, next) => prev.privilegeId - next.privilegeId);
  useEffect(() => {
    getMenuStatus(menuOpen);
  }, [menuOpen]);
  useEffect(() => {
    const privilege = JSON.parse(localStorage.getItem('privilege'));
    const transList = sortList(privilege[0].privilegeList).filter(
      (item) => item.linkUrl && item.loginType !== loginType.MOBILE_BROWSER
    );
    const qcsList = sortList(privilege[1].privilegeList).filter(
      (item) => item.linkUrl && item.loginType !== loginType.MOBILE_BROWSER
    );
    const adminList = sortList(privilege[2].privilegeList).filter(
      (item) => item.linkUrl && item.loginType !== loginType.MOBILE_BROWSER
    );
    const powderSystemList = sortList(privilege[3].privilegeList).filter(
      (item) => item.linkUrl && item.loginType !== loginType.MOBILE_BROWSER
    );
    setMenuList((prev) => ({
      ...prev,
      trans: transList.map((item) => ({
        text: item.privilegeName,
        icon: item.linkUrl.slice(1),
        path: item.linkUrl,
      })),
      powderSystemList: powderSystemList.map((item) => ({
        text: item.privilegeName,
        icon: item.linkUrl.slice(7),
        path: item.linkUrl,
      })),
      qcs: qcsList.map((item) => ({
        text: item.privilegeName,
        icon: item.linkUrl.slice(1),
        path: item.linkUrl,
      })),
      admin: adminList.map((item) => ({
        text: item.privilegeName,
        icon: item.linkUrl.slice(1),
        path: item.linkUrl,
      })),
    }));
  }, []);

  const checkIsActive = (path) => path === location.pathname;
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: menuOpen ? classes.drawerPaper : classes.drawerInit,
      }}
    >
      <Toolbar classes={{ root: classes.toolbar }} />
      <div className={classes.drawerContainer}>
        <div className={classes.switchIcon}>
          <div
            className={`${classes.openMenu} ${
              menuOpen ? classes.displayNone : classes.displayBlock
            }`}
          >
            <ListItemIcon>
              <MenuIcon iconName="open-menu" onClick={toggleMenu} />
            </ListItemIcon>
          </div>
          <div
            className={`${classes.closeMenu} ${
              !menuOpen ? classes.displayNone : classes.displayBlock
            }`}
          >
            <MenuIcon iconName="close-menu" onClick={toggleMenu} />
          </div>
        </div>
        <Divider />
        <List>
          {!menuOpen &&
            menuList.home.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          {menuOpen &&
            menuList.home.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem disabled>
            <ListItemText primary="移轉單系統" className={`${!menuOpen && classes.displayNone}`} />
          </ListItem>
          {menuList.trans &&
            !menuOpen &&
            menuList.trans.length &&
            menuList.trans.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          {menuList.trans &&
            menuOpen &&
            menuList.trans.length &&
            menuList.trans.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem disabled>
            <ListItemText
              primary="粉料資訊系統"
              className={`${!menuOpen && classes.displayNone}`}
            />
          </ListItem>
          {menuList.powderSystemList &&
            !menuOpen &&
            menuList.powderSystemList.length &&
            menuList.powderSystemList.map((item) => <MainMenuItem item={item} />)}
          {menuList.powderSystemList &&
            menuOpen &&
            menuList.powderSystemList.length &&
            menuList.powderSystemList.map((item) => <MainMenuItem item={item} isOpen />)}
        </List>
        <Divider />
        <List>
          <ListItem disabled>
            <ListItemText primary="QC單系統" className={`${!menuOpen && classes.displayNone}`} />
          </ListItem>
          {menuList.qcs &&
            !menuOpen &&
            menuList.qcs.length &&
            menuList.qcs.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          {menuList.qcs &&
            menuOpen &&
            menuList.qcs.length &&
            menuList.qcs.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem disabled>
            <ListItemText
              primary="Administration"
              className={`${!menuOpen && classes.displayNone}`}
            />
          </ListItem>
          {menuList.admin &&
            !menuOpen &&
            menuList.admin.length &&
            menuList.admin.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          {menuList.admin &&
            menuOpen &&
            menuList.admin.length &&
            menuList.admin.map((item) => (
              <Link to={item.path} key={item.text} style={{ textDecoration: 'none' }}>
                <ListItem button selected={checkIsActive(item.path)}>
                  <ListItemIcon>
                    <MenuIcon iconName={item.icon} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
        </List>
      </div>
    </Drawer>
  );
};

MainMenu.propTypes = {
  getMenuStatus: PropTypes.func.isRequired,
};

export default MainMenu;
