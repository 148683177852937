/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Accordion from '../../../components/SettingsAccordion/Accordion';

const PrivilegeCheckbox = ({
  transferPrivilegesGroup,
  powderPrivilegesGroup,
  qcPrivilegesGroup,
  settingPrivilegesGroup,
  transferPrivilegesGroupDispatch,
  powderPrivilegesGroupDispatch,
  qcPrivilegesGroupDispatch,
  settingPrivilegesGroupDispatch,
  isViewing,
  codeValue,
}) => (
  <>
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={3}>
        <Accordion
          title="移轉單系統"
          itemList={transferPrivilegesGroup}
          checkboxDispatch={transferPrivilegesGroupDispatch}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
      <Grid item xs={3}>
        <Accordion
          title="粉料資訊系統"
          itemList={powderPrivilegesGroup}
          checkboxDispatch={powderPrivilegesGroupDispatch}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
      <Grid item xs={3}>
        <Accordion
          title="QC單系統"
          itemList={qcPrivilegesGroup}
          checkboxDispatch={qcPrivilegesGroupDispatch}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
      <Grid item xs={3}>
        <Accordion
          title="Administration"
          itemList={settingPrivilegesGroup}
          checkboxDispatch={settingPrivilegesGroupDispatch}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
    </Grid>
  </>
);

PrivilegeCheckbox.propTypes = {
  transferPrivilegesGroup: PropTypes.array.isRequired,
  powderPrivilegesGroup: PropTypes.array.isRequired,
  qcPrivilegesGroup: PropTypes.array.isRequired,
  settingPrivilegesGroup: PropTypes.array.isRequired,
  transferPrivilegesGroupDispatch: PropTypes.func.isRequired,
  powderPrivilegesGroupDispatch: PropTypes.func.isRequired,
  qcPrivilegesGroupDispatch: PropTypes.func.isRequired,
  settingPrivilegesGroupDispatch: PropTypes.func.isRequired,
  isViewing: PropTypes.bool.isRequired,
  codeValue: PropTypes.array.isRequired,
};

export default PrivilegeCheckbox;
