import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ListItemText from '../ListItemText/ListItemText';
import ListItemIcon from '../ListItemIcon/ListItemIcon';
import ListItem from '../ListItem/ListItem';
import MenuIcon from '../MenuIcon/MenuIcon';

const MainMenuItem = ({ item, isOpen }) => {
  const location = useLocation();
  const checkIsActive = (path) => path === location.pathname;

  const handleNavigate = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}${item.path}`;
  };

  if (isOpen) {
    return (
      <div onClick={handleNavigate}>
        <ListItem button selected={checkIsActive(item.path)}>
          <ListItemIcon>
            <MenuIcon iconName={item.icon} />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      </div>
    );
  }
  return (
    <div onClick={handleNavigate}>
      <ListItem button selected={checkIsActive(item.path)}>
        <ListItemIcon>
          <MenuIcon iconName={item.icon} />
        </ListItemIcon>
      </ListItem>
    </div>
  );
};

MainMenuItem.defaultProps = {
  isOpen: false,
};

MainMenuItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

export default MainMenuItem;
