/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import useCommonStyles from '../useStyles';
import useStyles from './useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import regex from '../../../constants/validationRegex';
import QCHistory from './QCHistory';

const defaultTransInfo = {
  customer: '-',
  transQty: '-',
  machine: '-',
  elecStoveNo: '-',
  elName: '-',
  workOrder: '',
};

const TransInfo = ({
  transId,
  handleTransIdChange,
  transIdErr: defaultTransIdErr,
  handleFetch,
  transNGRecords,
  mergedTransIds,
  isValidNGTrans, // 是否為可做NG處理的移轉單號
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const { transIdError, setTransIdError } = useState(defaultTransIdErr);
  const [transInfo, setTransInfo] = useState(defaultTransInfo);
  const [targetTransId, setTargetTransId] = useState('');
  const [targetWorkOrder, setTargetWorkOrder] = useState('');

  const handleDelete = async (recode) => {
    const cancelToken = newCancelToken();
    const data = {
      listId: recode.listId,
    };
    if (mergedTransIds !== '') {
      data.mergedTransIds = mergedTransIds;
    } else {
      data.transId = transId;
    }
    const [status, , , noPrivilegeMsg] = await useAxios(
      '/ngDeleteHistory',
      data,
      true,
      cancelToken
    );
    if (status) {
      handleFetch();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法刪除',
          message:
            noPrivilegeMsg || `目前暫時無法刪除${transId}的資料請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleGetTransInfo = async (targetedTransId) => {
    const cancelToken = newCancelToken();
    const [status, result] = await useAxios(
      '/getTransInfo',
      {
        transId: targetedTransId,
      },
      true,
      cancelToken
    );
    if (status) {
      const {
        customerAlias,
        elecStoveNoMachineName,
        formMachineName,
        prodName,
        scOdno,
        transQuantity,
      } = result;
      setTransInfo({
        customer: customerAlias,
        transQty: transQuantity,
        machine: formMachineName,
        elName: prodName,
        workOrder: scOdno,
        elecStoveNo: elecStoveNoMachineName,
      });
      setTargetWorkOrder(scOdno);
      setTargetTransId(targetedTransId);
    }
  };

  const onTransIdChange = (e) => {
    handleTransIdChange(e);
    const { value } = e.target;
    setTargetTransId('');
    setTransInfo({ ...defaultTransInfo });
    if (value.length < 10) return;
    if (
      value.length === 10 &&
      !regex.SINTERING_TRANS_ID.test(value) &&
      !regex.FORMING_TRANS_ID.test(value)
    ) {
      setTransIdError(true);
      return;
    }
    handleGetTransInfo(value);
  };

  const handleDeleteTransNGRecord = (recode) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '您是否確定要刪除？',
        message: `刪除之後無法復原，確認刪除此筆資料嗎？`,
        handleConfirm: () => handleDelete(recode),
      },
    });
  };

  useEffect(() => {
    if (isValidNGTrans && transId !== targetTransId) {
      handleGetTransInfo(transId);
    }
  }, [transId, isValidNGTrans]);

  return (
    <>
      <PaperContainer className={commonClasses.container}>
        <Typography variant="h6">輸入NG的移轉單號</Typography>
        <Grid className={commonClasses.content} container>
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            <InputField
              id="transId"
              name="transId"
              label="移轉單號"
              placeholder="請輸入NG的壓型或燒結移轉單號"
              value={transId}
              onChange={onTransIdChange}
              type="text"
              error={transIdError}
              errormsg="請輸入正確且有效的NG壓型或燒結移轉單號"
            />
            {mergedTransIds.length > 0 && (
              <Typography variant="subtitle2">
                該單號已被合併，合併單號為：{mergedTransIds}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            <ReadonlyField
              label="客戶"
              name="customer"
              value={transInfo.customer}
              className={commonClasses.field_item}
            />
          </Grid>
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            <ReadonlyField
              label="數量"
              name="transQty"
              value={transInfo.transQty}
              className={commonClasses.field_item}
            />
          </Grid>
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            {transId.startsWith('TH') ? (
              <ReadonlyField
                label="爐號"
                name="stoveNo"
                value={transInfo.elecStoveNo}
                className={commonClasses.field_item}
              />
            ) : (
              <ReadonlyField
                label="機台號碼"
                name="machine"
                value={transInfo.machine}
                className={commonClasses.field_item}
              />
            )}
          </Grid>
          <Grid item xs={3} className={commonClasses.field_column_sintering} />
          <Grid item xs={6} className={commonClasses.field_column_sintering_productInfo}>
            <ReadonlyField
              label="產品資訊"
              name="elName"
              value={transInfo.elName}
              className={commonClasses.field_item}
            />
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            onClick={handleFetch}
            customClasses={`${classes.fetch_btn} ${transIdError ? classes.error : ''}`}
          >
            查詢
          </Button>
        </Grid>
      </PaperContainer>
      {targetTransId.length > 0 && (
        <QCHistory
          transId={targetTransId}
          workOrder={targetWorkOrder}
          isValidNGTrans={isValidNGTrans}
        />
      )}
      {transNGRecords.length !== 0 && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">NG處理歷史紀錄</Typography>
          <Grid container className={commonClasses.content}>
            <div className={classes.record_header}>
              <Grid item xs={1} className={classes.record_column}>
                <Typography variant="subtitle2">No</Typography>
              </Grid>
              <Grid item xs={2} className={classes.record_column}>
                <Typography variant="subtitle2">動作</Typography>
              </Grid>
              <Grid item xs={3} className={classes.record_column}>
                <Typography variant="subtitle2">更新時間</Typography>
              </Grid>
              <Grid item xs={6} className={classes.record_column}>
                <Typography variant="subtitle2">備註</Typography>
              </Grid>
            </div>
            <ul className={classes.record_content}>
              {transNGRecords.map((record) => (
                <li className={classes.record_row}>
                  <Grid item xs={1} className={classes.record_column}>
                    <Typography variant="body1">{record.id}</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.record_column}>
                    <Typography variant="body1">{record.action}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Typography variant="body1">{record.updateDateTime}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Typography variant="body1">{record.note}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<RemoveCircleOutlineRoundedIcon />}
                      onClick={() => handleDeleteTransNGRecord(record)}
                    >
                      刪除
                    </Button>
                  </Grid>
                </li>
              ))}
            </ul>
          </Grid>
        </PaperContainer>
      )}
    </>
  );
};

TransInfo.propTypes = {
  transId: PropTypes.string.isRequired,
  transIdErr: PropTypes.bool.isRequired,
  mergedTransIds: PropTypes.string.isRequired,
  handleTransIdChange: PropTypes.func.isRequired,
  handleFetch: PropTypes.func.isRequired,
  transNGRecords: PropTypes.array.isRequired,
  isValidNGTrans: PropTypes.bool.isRequired,
};

export default TransInfo;
